@import url('https://fonts.googleapis.com/css2?family=Mulish&display=swap');

body {
    font-family: 'Mulish', sans-serif;
}

.pageTitle{
	margin-bottom: 25px;
	display: flex;
}

.pageTitle span{
	line-height: 60px;
	font-weight: 600;
	font-size: 30px;
}

/* tabela de 3 colunas */
table{
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 1px;
    font-size: 15px;
}

 table th, td, tr{
    border-bottom: 3px solid#E6E7E9;
	padding: 10px;
	word-wrap: break-word;
    overflow: hidden;

}


table td{
    width: 30em ;
}


tr:nth-child(1):hover{
	background-color: white;
}

tr:hover{
	background-color: #e8e8e8;
}

table a{
	color: #863231;
	cursor: pointer;
	font-weight: bolder;
}

table a:hover{
	text-decoration: none;
	color: #54000A;
}

tbody tr:nth-child(even){
    background: #F9FAFC;
}

tbody tr:nth-child(even):hover{
    background: #e8e8e8;
}

td:nth-child(2) span{
	color: #537e43;
	cursor: pointer;
	font-weight: bolder;
}

tr:nth-child(1){
		color: #fff;
		background: #54000A;
		box-shadow: inset 0px -1px 0px #E6E7E9;
		pointer-events: none;
		
}

.goBack {
    display: inline-flex;
    width: 100%;
    padding: 5px;
    top: 0;
    height: 60px;
    border-bottom: 1px solid #65656578;
    position: sticky;
    z-index: 2;
    background-color: white;
	margin-bottom: 10px;
  }
  
.arrowBack{
	margin-top: auto;
	margin-bottom: auto;
	cursor: pointer;
	color: black;
}

.commonWidth{
	width: 80%;
	margin-left: auto;
	margin-right: auto;
}

label{
	font-weight: bold;
}

.loaderDiv{
	margin-left: auto;
	margin-right: auto;
	margin-top: 25%;
	width: min-content;
	
}

.searchBar input{
    width: 100%;
}

@media only screen and (max-width: 700px) {
	.pageContent{
		width: 100%;
	}

	.commonWidth{
		width: 95%;
    }

    .searchOrder{
        flex-direction: column;
    }

    .searchOrder > *{
        margin-bottom: 10px;
    }

    .listTable{
        overflow-x: scroll;
    }

    table{
        width: 1000px!important;
    }
}

/* Div que engloba a barra de pesquisa e o ordenar por */
.searchOrder{
    display: flex;
    position: relative;
}

/* pesquisar */
.searchBar{
    position: relative;
}
.searchBar input{
    font-size: 24px;
}


/* Ordernar por: */
.orderBy span{
    display: flex;
    position: relative;
    font-family:  var(--font);
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: normal;

    color: #000000;

}


.removeButton{
	content: "";
    background: url("./images/delete.svg");
    background-repeat: no-repeat;
    background-size: 36px 36px;
    
    display: block;
    height: 32px;
    width: 32px;
    
    outline: none;
    box-shadow: none;
    padding: 0;
}
.removeButton:hover{
	background-size: 37px 37px;
}


/* Navegação de páginas */
.navigation{
    height:100%;
    border-collapse:collapse;
    display : flex;
    position: relative;
}

/* Próxima */
.next{
    position: absolute;
    right: 4em;

}
/* Anterior */
.previous{
    position: absolute;
    left: 4em;

}

.selectBoxContainer{
    position: relative;
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
}

/* Seção do select utilizado nas listagens */
.selectBoxContainer span{
    font-size: 1.5rem;
    color: #6f6f6f;
    position: relative;
    margin: auto;
    margin-right: 20px;
}

.selectBoxContainer  select{
    background: #FFFFFF;
    border: 2px solid #F0F0F0;
    box-sizing: border-box;
    border-radius: 8px;
    height: 50px;
    padding: 10px;
    min-width: 0;
    margin: auto;
}

.selected {
    border: 2px solid #e4e2e2;
    border-radius: 6px;
    
    color: #6f6f6f;
    position: relative;
  
    order: 0;
}

.selected::after {
    content: "";
    background: url("./images/down-arrow.svg");
    background-size: 70%;
    background-repeat: no-repeat;
  
    position: absolute;
    height: 70%;
    width: 32px;
    right: 10px;
    top: 27px;
  
    transition: all 0.4s;
}

.selectBox .optionsContainer.active + .selected::after {
    transform: rotateX(180deg);
    top: -4px;
  }

.selectBox{
    display: flex;
    width: 20rem;
    flex-direction: column;
    margin-top: 0.75rem;
}

.selectBox .optionsContainer {
    background-color: #fff;
    position: absolute;
    border: 2px solid #e4e2e2;
    border-radius: 6px;
    color: #373737;
    width: 16em;
    max-height: 0;
    margin-top: 3.15em;
    opacity: 0;
    transition: all 0.4s;
    overflow: hidden;
    order: 1;
    
    z-index: 3;
}

.selectBox .optionsContainer.active{
    max-height: 240px;
    opacity: 1;
}

.selectBox .option, .selected{
    padding:  12px 24px;
    cursor: pointer;
}

.selectBox .option .radio, .selected.active .radio{
    display: none;
}


.selectBox .option:hover{
    background: #eeecec;
}

.selectBox label{
    cursor: pointer;
}

/* Fim da seção do select utilizado nas listagens */

.searchBar input{
    border: none;
}