* {
    -webkit-print-color-adjust: exact !important;   /* Chrome, Safari, Edge */
    color-adjust: exact !important;          
}

.certification {
    background-color: white;
}

.certification .pageTitle span{
    margin: auto;
}

.logo{
    width: 100px;
    border-radius: 100%;
}

.certificationContent{
    border: 1px solid black;
    position: relative;
    display: table;
    padding: 15px;
    min-width: 1000px;
    max-width: 1000px;
    min-height: 600px;
    max-height: 600px;
    box-sizing: unset;
    font-size: 20px;
    margin-bottom: 20px;
    font-family: Arial;
    overflow: hidden;
    background-color: white;
    
}

.certificationBorder{
    margin: 5px;
    border: 5px solid #54000A;
    padding: 10px;
    min-width: 1000px;
    min-height: 600px;
    background: linear-gradient(0deg, rgb(255 255 255 / 89%), rgb(255 255 255 / 89%)), url('../../images/certificationBackground.jpg') no-repeat center;
    background-color: white;

}

.certificationContent .bottom{
    display: flex;
    position:  relative;
    font-weight: bolder;
    height: auto;
}

.bottomLeft{
    display: flex;
    position: absolute;
    bottom: 25px;
    left: 20px;
}

.bottomMiddle{
    position: absolute;
    display: flex;
    bottom: 30px;
    left: 50%;
    transform: translate(-50%, -50%);
}

.bottomMiddleSignature{
    display: flex;
    bottom: 40px;
    justify-content: center;
    margin-top: 30px;
}

.bottomRight{
    display: flex;
    position: absolute;
    bottom: 25px;
    right: 20px;
}

.certificationContent .logos{
    display: flex;
    margin-left: auto;
    margin-right: auto;
}

.certificationContent .logos img{
    margin-left: 20px;
    margin-right: 20px;
    width: 70px;
    height: 70px;
}

.certificationContent .top{
    display: grid;
    max-height: min-content;
    align-self: center;
    text-align: center;
    font-size: 30px;
    padding: 0 50px;
}

.certificationContent .top span:nth-child(1), .certificationContent .top span:nth-child(2){
    font-size: 40px;
    font-weight: bolder;
    color: #54000A;
}

.certificationContent .top span:nth-child(2){
    margin-bottom: 40px;
}

.topics{
    min-height: 400px;
    max-height: 400px;
    overflow: hidden;
    display: flex;
}

.topics .left, .topics .right{
    font-family: monospace;
    font-size: 15px;
    width: 50%;
    text-align: left;
    line-break: auto;
    word-break: break-all;
    line-height: normal;
}

.topics .left{
    border-right: 1px solid black;
}

.certificationContent .middle {
    display: grid;
    position: absolute;
    bottom: 50px;
    width: 50%;
    left: 25%;
    left: 25%;

}

.certificationContent .signatures{
    margin-left: auto;
    margin-right: auto;
    display: flex;
}

.certificationContent .signature {
    display: grid;
    align-self: center;
    text-align: center;
    font-size: 17px;
    margin-left: 20px;
    margin-right: 20px;
}

.up{
    margin-top: -150px;
}



.certificationContent .signatureImg{
    width: 100px;
    border-bottom: 1px solid black;
}

.qrTitle{
    display: grid;
    height: min-content;

}

.qrTitle span{
    height: min-content;
    margin: 0 auto;
}
.qrCode{
    margin: 0 auto;
}
.qrCode img{
    width: 100px;
}

.signature label{
    font-size: 12px;
    width: 100px;
    word-wrap: break-word;
    word-break: normal;
}

.certificationText{
    font-size: 26px;
}

#divToPrint{
    margin: auto;
    width: min-content;
}

#printButton{
    display: grid;
    margin: auto;
}

@media print{@page {size: landscape}}

@media print {
    .certificationContent { page-break-after: always; }
}