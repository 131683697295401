@import url(https://fonts.googleapis.com/css2?family=Mulish&display=swap);
.dashboard{
    margin-left: auto;
    margin-right: auto;
    max-width: 2500px;
    display: flex;
    font-size: 20px;
}

.left{
    width: -webkit-min-content;
    width: min-content;
}

.right{
    width: 100%;
}
.siteContent{
    min-height: 51.3vw;
    display: flex;
}

.menuType:nth-child(3){
    margin-top: 30px;
    border-top: 0.1px solid #bcbcbc;

}

.contentContainer{
    width: 100%;
    display: grid;
    justify-items: center;
    background-color: white;
    border-left: 1px solid #ddd;
    }

.content {
    width: 100%;
    min-width: 500px;
    margin-left: auto;
    margin-right: auto;
}





@media only screen and (max-width: 1100px) {
    .sidebarRight {
        min-width: 175px;
    }
}

@media only screen and (max-width: 700px) {
    .content{
        margin-top: 10px;
        width: 100%;
        min-width: 0;
        font-size: 15px;
    }

    .sidebarRight{
        display: none;
        position: fixed;
        background-color: #f8f8f8;
        width: 100%;
        height: 100vw;
        z-index: 3;
    }
}
.courses{
	padding: 50px;
}

.coursesRender {
	margin-bottom: 10px;
	display: flex;
	flex-direction: row;
	flex-flow: wrap;
}

#cardCourse {
	border-radius: 12px;
	background-color: white;
	color: black;
	font-weight: bolder;
	/* width: 260px; */
	margin: 0;
	height: 280px;
	margin-right: 18px;
	margin-bottom: 18px;
}

#cardHeaderCourse {
	background-color: white;
	border-top-left-radius: 15px;
	border-top-right-radius: 15px;
	padding: 15px 15px 0 15px;
	font-size: 23px;
	border: 0;
	display: grid;
}

#cardHeaderCourse span{
	cursor: pointer;
	display: -webkit-box;
    -webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;

}

.courses .pageTitle span{
	margin-right: 5px;
}

#cardBodyCourse{
	border: 0;
	padding: 10px 15px 0 15px;
	white-space: pre-line;
	margin-left: 5px;
	font-size: 15px;
	color: rgba(32, 32, 32, 0.5);
	height: -webkit-min-content;
	height: min-content;
}

#cardBodyCourse span{
	display: -webkit-box;
    -webkit-line-clamp: 6;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

#cardButtonCourse{
	padding: 0;
	margin: 0;
	border: none;
	background-color: #f7f7f7;
	transition: 5s;
}

#cardButtonCourse:active{
	transition: 2s;
}

.select-button{
	padding: 0;
	margin: 0;
	border: none;
	background-color: #f7f7f7;
}

#cardFooterCourse{
	display: flex;
	justify-content: center;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
	min-height: 40px;
	grid-gap: 24px;
	gap: 24px;
}

#cardFooterCourse span{
	cursor: pointer;
	color: #fff;
	background-color: #54000A;
	padding: 8px 48px 8px 48px;
	border-radius: 8px;
}

.courses th:nth-child(1){
	width: 15%;
}

.courses th:nth-child(2){
	width: 42.5%;
}

.courses th:nth-child(3){
	width: 42.5%;
}

.paginator {
	margin-top: 10px;
	justify-content: center;
	display: grid;
}

.paginatorComponent {
	list-style: none;
	display: flex;
}

.itemPaginateButton {
	width: 24px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.itemPaginateText {
	font-size: 10px;
}

.arrows {
	display: flex;
}

.arrowLeft, .arrowRight{
	background-color: white;
	width: 30px;
	height: 30px;
}

.pageTitle {
	align-items: center;
	display: flex;
}

.pageTitleLeft{
	display: flex;
	align-items: center;
	justify-content: flex-start;
	width: 100%;
	height: 60px;
}

.pageTitleRight{
	display: flex;
	align-items: center;
	justify-content: flex-end;
	width: 100%;
	height: 60px;
}

.form {
	margin-left: 50px;
}

.inputForm {
	margin-right: 20px;
}

.new-folder-div{
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
}

.new-folder-button{
	display: flex;
	align-items: center;
	justify-content: center;
	align-self: flex-end;
	grid-gap: 10px;
	gap: 10px;
	background-color: #fff;
	text-align: center;
	border-radius: 12px;
	border: solid 1px #54000A;
	padding: 10px;
}

.new-folder-button:hover {
	cursor: pointer;
}

.new-folder-text{
	padding: 0;
	margin: 0;
}

.folders-div{
	display: flex;
	grid-gap: 25px;
	gap: 25px;
}

.folders-card {
	display: flex;
	align-items: center;
	padding: 32px;
	grid-gap: 114px;
	gap: 114px;
	border-radius: 8px;
	border: solid 1px #54000A;
}

.folders-card:hover {
	cursor: pointer;
}

.folders-text{
	margin: 0;
	font-size: 24px;
	color: #54000A;
}

.folders-number{
	margin: 0;
	font-size: 24px;
}

.selected-buttons {
	display: flex;
	flex-direction: row;
	grid-gap: 16px;
	gap: 16px;
}

.modal-button {
	background-color: #54000A;
	color: #fff;
	border: none;
	border-radius: 8px;
	padding: 8px 48px 8px 48px;
}

.modal-buttons-div {
	display: flex;
	justify-content: center;
	grid-gap: 16px;
	gap: 16px;
	margin-top: 16px;
}

@media screen and (max-width: 1024px){
	.courses {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	.pageTitle {
		flex-direction: column;
	}
	
	.form {
		margin-top: 10px;
	}

	.inputForm {
		margin-bottom: 10px;
	}
}
/* Estilos para o Modal */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    background-color: blue;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
  }
  

/* Estilo geral do container do componente */
.select-container {
    padding: 20px;
    font-family: Arial, sans-serif;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 300px;
    margin: 20px auto;
  }
  
  /* Estilo para o título */
  .select-heading {
    color: #54000a;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 15px;
    text-align: center;
  }
  
  /* Estilo do select */
  .select-box {
    width: 100%;
    padding: 10px;
    border: 2px solid #54000a;
    border-radius: 8px;
    background-color: #fff;
    font-size: 16px;
    color: #333;
    transition: border-color 0.3s ease-in-out;
  }
  
  /* Foco no select */
  .select-box:focus {
    border-color: #54000a;
    outline: none;
  }
  
  /* Estilo para o box de detalhes quando uma opção é selecionada */
  .selected-details {
    margin-top: 20px;
    padding: 10px;
    background-color: #f2f2f2;
    border-radius: 8px;
    border: 1px solid #54000a;
  }
  
  /* Estilo para os textos dentro do box de detalhes */
  .selected-details h3 {
    color: #54000a;
    font-size: 18px;
  }
  
  .selected-details p {
    font-size: 14px;
    color: #333;
  }
  
.userInformations{
    padding: 50px;
    margin-top: -50px;
}

.userInformationsContent{
    display: grid;
}


.userInformationsContent form{
    display: grid;
    width: 100%;
    min-width: 0;
}

.userInformationsContent input, .userInformationsContent select{
    margin-bottom: 20px;
    background: #FFFFFF;
    border: 2px solid #F0F0F0;
    box-sizing: border-box;
    border-radius: 8px;
    height: 50px;
    padding: 10px;
    min-width: 0;
}



.userInformations select{
    background-color: white;
}

.userInformations label{
    font-weight: bold;
}

.userInformationsContent .buttonGroup {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    justify-content: space-between;
    padding-left: 10px;
    padding-right: 10px;
}

.userInformationsContent .buttonGroup button {
    border-radius: 6px;
    padding: 5px;
    margin: auto;
}

input[type='submit']{
    width: 100%;
    background: #54000A;
    color: white;
    border-radius: 0;
    margin-top: 25px;
}


.userInformationsContent span{
    margin-top: 10px;
}

.userCourses{
    display: none;
}

.userInformations ul{
    height: 100%;
    display: grid;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    -webkit-padding-start: 0;
            padding-inline-start: 0;
    list-style: none;
    justify-content: center;
}

.userInformations li{
    padding: 0 20px;
    cursor: pointer;
    display: grid;
}

.userInformations li span{
    align-self: center;
}

.userInformations li:hover{
    background-color: #dedede;
}

.cpfAndDefaulter{
    display: flex;
    flex-direction: row;
    width: 100%;
}

.labelInput{
    display: grid;
    flex-basis: 50%;

}

.labelInput:last-child{
    margin-left: 30px;

}

.removeUserButton{
    background-color: transparent;
    border: 0;
    margin: auto;
}



.removeUser{
    content: "";
    background: url(/static/media/delete.d280d280.svg);
    background-repeat: no-repeat;
    background-size: 96px 96px;
    
    display: block;
    height: 96px;
    width: 96px;
    
    outline: none;
    box-shadow: none;
    padding: 0;
    
}


@media (max-width: 768px){
    .cpfAndDefaulter{
        display: block;
        flex-direction: row;
        width: 100%;
    }

    .userInformations .pageContent{
        min-width: 0;

    }

    .labelInput:last-child{
        margin-left: 0;
    
    }
}
.courseRenderInformation{
    margin-bottom: 40px;
}

.editCourse form{
    display: grid;
    width: 100%;
}

.editCourse label{
    font-weight: bold;
}

.editCourse input, .editCourse textarea{
    margin-bottom: 20px;
    min-width: 0;
    border: 1px solid #545454;
    border-radius: 15px;
    padding: 5px;
}
.editCourse textarea{
    min-height: 200px;
    max-height: 200px;
}

.courseStudents{
    display: none;
}

.leagueCourseStudents{
    display: none;
}

.course .administratorSign{
    background-color: #bcbcbc;
    width: 100%;
}

.course .cadastrateStudent{
    display: none;
    border: 1px solid rgba(0,0,0,.125);
    padding: 15px;
    border-radius: 20px;
}

.course .cadastrateStudent span{
    font-weight: bolder;
}

.course .cadastrateStudentContent{
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}
.course .cadastrateStudent form{
    display: grid;
    margin-top: auto;
    margin-bottom: auto;
}

.course .cadastrateStudent input:nth-child(1){
    width: 100%;
}

.course .cadastrateStudent input:nth-child(2){
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
}

.course .editCourse, .cadastrateStudent{
    margin-left: auto;
    margin-right: auto;
}

.course ul{
    height: 100%;
    display: grid;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    -webkit-padding-start: 0;
            padding-inline-start: 0;
    list-style: none;
    justify-content: center;
}

.course li{
    padding: 0 20px;
    cursor: pointer;
    display: grid;
}

.course li span{
    align-self: center;
}

.course li:hover{
    background-color: #dedede;
}
.userInformationsAll{
    margin-top: 0;
}

#cardInformations {
    border-radius: 20px;
    margin-bottom: 10px;
}

#cardBodyInformations {
    border-radius: 20px;
}

.userInformations button{
    background-color: transparent;
    border: none;
}
.userInformations button:hover{
    color: #54000A;
    font-weight: bold;
}

.removeFromCourse {
    background-color: #d34040;
    border: 1px solid black;
    border-radius: 5px;
}

.searchIcon{
    content:"";
    background: url(/static/media/search.a5829f1b.svg);
    background-repeat: no-repeat;
    background-size: 48px 48px;

    width: 50px;
    position: relative;
    z-index: 2;
}

.searchBar{
    display: flex;
}

.registerCourse{
    padding: 50px;
}

.registerCourse form{
    display: grid;
    width: 100%;
}

.registerCourse input, .registerCourse textarea, .registerCourse select {
    margin-bottom: 20px;
    background: #FFFFFF;
    border: 2px solid #F0F0F0;
    border-radius: 8px;
    height: 50px;
    padding: 10px;
}

input[type="radio"]{
    height: inherit;
    margin: 0;
    margin-right: 10px;
}

.react-date-picker {
    background: #FFFFFF;
    border: 2px solid #F0F0F0;
    border-radius: 8px;
    margin-bottom: 20px;

}

.react-date-picker__wrapper {
    border: 0!important;
}

.react-date-picker__inputGroup input[type="number"]{
    height: inherit;
    border: 0;
    margin-bottom: inherit;
}

.registerCourse label{
    font-weight: bold;
}

.registerCourse textarea{
    min-height: 200px;
    max-height: 200px;
}

.registerCourse input[type='submit'] {
    width: 100%;
    background: #54000A;
    color: white;
    border-radius: 0;
    margin-top: 25px;
}

.registerCourse .buttonGroup input{
    width: 100%;
    background: #54000A;
    color: white;
    border-radius: 0;
    height: 50px;
    border: 0;
}

.plusFields{
    display: grid;
}

.plusLabel{
    display: block;
}

.plusLabel svg{
    position: relative;
    float: right;
}

.plusFields select{
    width: 100%;
}


.plusFields label{
    font-weight: 100;
    font-size: 15px;
}

.addMoreFields{
    border: 1px solid black;
    border-radius: 100%;
    width: 20px;
    height: 20px;
}

.addMoreFields span{
    margin: auto;
}

.radio{
    display: flex;
}

.radio > * {
    margin-right: 10px;
}

.radioOption > * {
    margin-right: 5px;
}

.workloadInput{
    width: 100%;
}

.topicsInput{
    width: 100%;
}

.removeCourseButton{
    background-color: transparent;
    border: 0;
    margin: auto;
}


.removeCourse{
    content: "";
    background: url(/static/media/delete.d280d280.svg);
    background-repeat: no-repeat;
    background-size: 96px 96px;
    
    display: block;
    height: 96px;
    width: 96px;
    
    outline: none;
    box-shadow: none;
    padding: 0;
    
}

.buttonGroup{
    display: flex;
    flex-direction: column-reverse;
}
.errorStudents {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.errorStudents hr {
    clear:both;
    display:block;
    width: 100%;               
    background-color: #dfdfdf;
    height: 1px;
}

.errorStudents span {
    text-align: center;
}

.errorStudents ul {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 100%;
    grid-gap: 15px;
    gap: 15px;
    margin-top: 15px;
    margin-bottom: 10px;
}

.errorStudents ul li {
    text-align: center;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: default;
}

@media (max-width: 900px) {
    .errorStudents ul {
        grid-template-columns: 1fr;
    }
}
.downloadButton{
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 15px;
}
.courseRender {
    margin-bottom: 10px;
}

.courses th:nth-child(1){
	width: 15%;
}

.courses th:nth-child(2){
	width: 42.5%;
}

.courses th:nth-child(3){
	width: 42.5%;
}

.paginator {
	margin-top: 10px;
	justify-content: center;
	display: grid;
}

.arrows {
	display: flex;
}

.arrowLeft, .arrowRight{
	border: 0.1px solid black;
	width: 30px;
	height: 30px;
}

#cardInformations {
    border-radius: 20px;
    margin-bottom: 10px;
}

#cardBodyInformations {
	border-radius: 20px;
}


.registerLogo{
    padding: 50px;
}

.registerLogoFields{
    display: grid;
    margin-left: auto;
    margin-right: auto;
}

.registerLogoFields form{
    display: grid;
}

.registerLogoFields input, .registerLogoFields select{
    margin-bottom: 20px;
    min-width: 0;
    border: 1px solid #545454;
    padding: 5px;
    background: #FFFFFF;
    border: 2px solid #F0F0F0;
    box-sizing: border-box;
    border-radius: 8px;
}

.userInformations select{
    background-color: white;
}

.userInformations label{
    font-weight: bold;
}

.registerLogoFields input:last-child{
    width: 100%;
    background: #54000A;
    color: white;
    border-radius: 0;
    margin-top: 25px;
}

.registerLogoFields span{
    margin-top: 10px;
}


.registerSignature{
    padding: 50px;
}

.registerSignatureFields{
    display: grid;
    margin-left: auto;
    margin-right: auto;
}

.registerSignatureFields form{
    display: grid;
}

.registerSignatureFields input, .registerSignatureFields select{
    margin-bottom: 20px;
    min-width: 0;
    border: 1px solid #545454;
    padding: 5px;
    background: #FFFFFF;
    border: 2px solid #F0F0F0;
    box-sizing: border-box;
    border-radius: 8px;
}

.userInformations select{
    background-color: white;
}

.userInformations label{
    font-weight: bold;
}

.registerSignatureFields input:last-child{
    width: 100%;
    background: #54000A;
    color: white;
    border-radius: 0;
    margin-top: 25px;
}

.registerSignatureFields span{
    margin-top: 10px;
}
:root{
    --font: Mulish, Sans-seriff;
    --alignment-search-order: 7em;
}

.renderLeagues{
    padding: 50px;
    width: 100%;
    max-width: 1430px;
}

.leagueContent{
    display: grid;
    align-items: center;
    white-space: nowrap;
}


.registerLeague{
    padding: 50px;
}

.registerLeague form{
    display: grid;
}

.registerLeague input, .registerLeague select, .registerLeague textarea{
    margin-bottom: 20px;
    min-width: 0;
    border: 1px solid #545454;
    padding: 5px;
    background: #FFFFFF;
    border: 2px solid #F0F0F0;
    box-sizing: border-box;
    border-radius: 8px;
}

.registerLeague textarea{
    height: 200px;
    max-height: 200px;
    min-height: 200px;
}

.registerLeague select{
    background-color: white;
}

.registerLeaguelabel{
    font-weight: bold;
}

.registerLeague input:last-child{
    width: 100%;
    background: #54000A;
    color: white;
    border-radius: 0;
    margin-top: 25px;
}

.registerLeague span{
    margin-top: 10px;
}

.removeLeagueButton{
    background-color: transparent;
    border: 0;
    margin: auto;
}


.removeLeague{
    content: "";
    background: url(/static/media/delete.d280d280.svg);
    background-repeat: no-repeat;
    background-size: 96px 96px;
    
    display: block;
    height: 96px;
    width: 96px;
    
    outline: none;
    box-shadow: none;
    padding: 0;
    
}

.buttonGroup{
    display: flex;
    flex-direction: column-reverse;
}

:root{
    --border-radius:6px;
}


.leagueRenderInformation{
    margin-bottom: 40px;
}

.editLeague form{
    display: grid;
    width: 100%;
}

.cadastrateStudent form span{
    font-weight: bold;
}


.editLeague input, .editLeague textarea{
    margin-bottom: 20px;
    min-width: 0;
    border: 2px solid #F0F0F0;
    border-radius: var(--border-radius);
    padding: 5px;
}

.editLeague textarea{
    min-height: 200px;
    max-height: 200px;
}

.leagueStudents{
    display: none;
}

.league .cadastrateStudent input[type='submit'] {
    width: 100%;
    background: #54000A;
    color: white;
    border-radius: 0;
    height: 50px;
    border: 0;
}

.league .administratorSign{
    background-color: #bcbcbc;
    width: 100%;
}

.league .cadastrateStudent{
    display: none;
    padding: 50px;
}

.league input, .league textarea, .league select {
    margin-bottom: 20px;
    background: #FFFFFF;
    border: 2px solid #F0F0F0;
    border-radius: 8px;
    height: 50px;
    padding: 10px;
}

.league .cadastrateStudent span{
    font-weight: bolder;
}

.league .cadastrateStudent input{
    margin-bottom: 20px;
    
    border: 2px solid #F0F0F0;
    border-radius: var(--border-radius);
}

.league .cadastrateStudent input:last-child{
    width: -webkit-min-content;
    width: min-content;
    margin-left: auto;
    margin-right: auto;
}


.league .cadastrateStudentContent{
    width: 100%;
}
.league .cadastrateStudent form{
    display: grid;
    margin-top: auto;
    margin-bottom: auto;
}

.league .cadastrateStudent input:nth-child(1){
    width: 100%;
}

.league .cadastrateStudent input:nth-child(2){
    margin-top: 10px;
}

.editLeague, .cadastrateStudent{
    margin-left: auto;
    margin-right: auto;
}

.league ul{
    height: 100%;
    display: grid;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    -webkit-padding-start: 0;
            padding-inline-start: 0;
    list-style: none;
    justify-content: center;
}

.league li{
    padding: 0 20px;
    cursor: pointer;
    display: grid;
}

.league li span{
    align-self: center;
}

.league li:hover{
    background-color: #dedede;
}

.registerStudent input{
    background: #54000A;
    color: #fff;
    height: 2.5em;
    width: 1em;
}
#cardInformations {
    border-radius: 20px;
    margin-bottom: 10px;
}

#cardBodyInformations {
    border-radius: 20px;
}

.logoInformations{
    padding: 50px;
    width: 100%;
    max-width: 1430px;
}
.logoInformations th:nth-child(1){
    width: 15%;
    min-width: 46px;
}

.logoInformations th:nth-child(2){
	width: 35%;
}

.logoInformations th:nth-child(3){
	width: 35%;
}

.logoInformations th:nth-child(4){
	width: 15%;
}

.logoInformations button{
    border: 0px;
    background-color: transparent;
    outline: none;
    box-shadow: none;
    padding: 0;
    width: 15px;
}

#cardInformations {
    border-radius: 20px;
    margin-bottom: 10px;
}

#cardBodyInformations {
    border-radius: 20px;
}

.signatureInformations{
    padding: 50px;
    width: 100%;
    max-width: 1430px;
}

.signatureInformations th:nth-child(1){
    width: 15%;
    min-width: 46px;
}

.signatureInformations th:nth-child(2){
	width: 35%;
}

.signatureInformations th:nth-child(3){
	width: 35%;
}

.signatureInformations th:nth-child(4){
	width: 15%;
}

.signatureInformations button{
    border: 0px;
    background-color: transparent;
    outline: none;
    box-shadow: none;
    padding: 0;
}
.userInformationsAll{
    margin-top: 0;
}

#cardInformations {
    border-radius: 20px;
    margin-bottom: 10px;
}

#cardBodyInformations {
    border-radius: 20px;
}

.userInformations button{
    background-color: transparent;
    border: none;
}
.userInformations button:hover{
    color: #54000A;
    font-weight: bold;
}

.removeFromCourse {
    background-color: #d34040;
    border: 1px solid black;
    border-radius: 5px;
}

.searchIcon{
    content:"";
    background: url(/static/media/search.a5829f1b.svg);
    background-repeat: no-repeat;
    background-size: 48px 48px;

    width: 50px;
    position: relative;
    z-index: 2;
}

.searchBar{
    display: flex;
}

.sidebarLeft{
    top:0;
    width: 25%;
    min-width: 300px;
    align-self: flex-start;
    background-color: #320000;
    height: 100%;
    color: white;
}

.sidebarLeft > *{
    -webkit-touch-callout: none;
    -webkit-user-select: none;
            user-select: none;
}

.fixedMenu{
    position: -webkit-sticky;
    position: sticky;
    top: 0;
}
.sidebarLeft ul{
    width: 100%;
    -webkit-padding-start: 0;
            padding-inline-start: 0;
    margin: 0;
}

.sidebarLeft .menu {
    padding: 0;
    list-style: none;
    margin: 20px auto;
    clear: both;
    display: table;
    margin-bottom: 0px;
}

.sidebarLeft .list{
    box-sizing: border-box;
    border-bottom: 1px solid#e6e6e6;
    position: relative;
    vertical-align: sub;
}

.menuLink.active {
    color: #863231;
}

.menuLink.active:hover {
    color: #863231;
}

.sidebarIcon{
    font-size: 25px;
    margin-right: 10px;
}

.toggle{
    position: absolute;
    right: 10px;
}

#menuAccordion .card{
    border: 0;
    background-color: transparent;
    cursor: default;
}

#menuAccordion .card-header{
    background-color: transparent;
    border: 0;
    padding: 15px 25px;
}

#menuAccordion .card-body{
    padding: 0;
}

#menuAccordion .collapse, #menuAccordion .collapsing{
    background-color: rgba(255,255,255,0.05);
}

.sidebarLeft .items{
    height: 0px;
    overflow: hidden;
}

.sidebarLeft .items:last-child{
    border-bottom:none;
}


.sidebarLeft li{
    width: 100%;
    list-style: none;
    cursor: pointer;
    font-size: 20px;
    display: grid;
    padding: 15px 0 15px 20%;
}

.sidebarLeft li a{
    justify-content: center;
    display: grid;
}

.sidebarLeft a{
    text-decoration: none;
    color: white;
}

.sidebarLeft a:hover{
    -webkit-text-emphasis: none;
            text-emphasis: none;
    text-decoration: none;
    color:white;
    transition: 300ms all;
}

.sidebarRight{
    width: 25%;
    min-width: 230px;
}


.search{
    padding: 15px;
    display: grid;
}

#searchForm{
    width: 100%;
    display: grid!important;
}

#searchInputDiv{
    width: 100%;
    height: 0;
    overflow: hidden;
    display: flex;
}

#searchForm:hover  #searchInputDiv{
    height: 25px;
    transition: height 0.5s;
}

#searchForm input{
    width: 90%;
    height: 25px;
    box-sizing:border-box;
    overflow: hidden;
}

#searchForm  button{
    margin-left: auto;
    margin-right: auto;
    width: -webkit-min-content;
    width: min-content;
    height: 35px;
    border: 0;
    background-color: transparent;
}

.logout{
    padding: 10px 0 10px 25px;
    border-top:2px solid rgba(134,50,49.1);
    color: rgba(134,50,49.1);
    cursor: pointer;
}

@media only screen and (max-width: 800px) {
    .sidebarLeft{
        min-width: 175px;
    }
}

@media only screen and (max-width: 700px) {
    .sidebarLeft{
        width: -webkit-min-content;
        width: min-content;
        min-width: 0;
        position: fixed!important;
        z-index: 1;
    }

    .fixedMenu{
        position: relative!important;
    }

    .menuContent{
        display: none;
    }

    .right{
        margin-left: 50px;
    }


}
.registerRedirectText {
    display: flex;
    flex-direction: column;
    max-width: 483px;
}

@media (max-width: 768px) {
    .registerForm{
        margin: auto;
    }
}

@media (max-width: 1700px) and (min-width: 769px) {
    .registerForm {
        transform: scale(0.75, 0.75)!important;

    }
}

@media (max-width: 1200px) and (min-width: 769px) {
    .registerForm{
        transform: scale(0.75, 0.75)!important;
        margin-top: 200px!important;

    }
}
.loginRedirectText {
    display: flex;
    flex-direction: column;
    max-width: 483px;
}


.authentication {
    display: flex;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    max-height: 1000px;
    margin: auto;
    max-width: 2000px;
    min-width: 1290px;
}

.loginSide {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-height: 1000px;
}

.sideContent {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    margin-left: 75px;
    margin-top: 32px;
    margin-right: 359px;
}

.sideContent img {
    width: 377px;
    height: auto;
    margin-bottom: 32px;
}

.sideContent h1 {
    font-weight: bold;
    font-size: 64px;
    line-height: 64px;
    letter-spacing: 0.2px;
    color: #F2F2F2;
    margin-bottom: 40px;
    margin-left: 41px;
}

.sideContent span {
    font-weight: normal;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.3px;
    color: #F2F2F2;
    margin-left: 41px;
}

.fields {
    min-width: 44.895833333%;
    display: flex;
    flex-direction: column;
    flex: 1 1;
    justify-content: center;
}

.fields form {
    display: flex;
    flex-direction: column;
    margin-left: 10%;
    margin-right: 10%;
}

.fields form h1 {
    font-weight: 700;
    font-size: 48px;
    line-height: 60.24px;
    margin-bottom: 40px;
}

.fields form input, .fields form select {
    max-width: 483px;
    height: 69px;
    margin-bottom: 40px;
    border: 2px solid #f0f0f0;
    box-sizing: border-box;
    border-radius: 8px;
    font-size: 24px;
    padding: 16px;
    line-height: 30.12px;
    background-color: white;
}

.fields form input:focus, .fields form select:focus {
    outline: none;
    border: 2px solid #54030D;
}

.fields form button {
    max-width: 483px;
    height: 75px;
    background: #54000A;
    border: none;
    color: white;
    font-size: 24px;
    line-height: 30.12px;
    text-align: center;
    margin-bottom: 40px;
}

.loginRedirectText {
    display: flex;
    flex-direction: column;
    max-width: 483px;
}

.fields form span {
    font-size: 24px;
    line-height: 30.12px;
    text-align: center;
    color: rgba(32, 32, 32, 0.75);
    margin-bottom: 16px;
}

.fields form span .reactLink {
    color: #54000A;
}

@media (max-width: 1200px) and (min-width: 769px) {
    .fields form {
        margin-top: 50px;

    }
}

@media (max-width: 768px) {
    .authentication {
        width: 100%;
        flex-direction: column-reverse;
        align-items: center;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        max-height: unset;
        min-width: unset;

    }

    .side {
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        padding-right: 20px;
    }

    .sideContent {
        margin: 0 0 20px 0;
    }

    .fields {
        padding: 20px;
    }

    .fields form {
        margin: 0;
    }
}

@media (max-width: 1700px) and (min-width: 769px) {

    .side {
        max-width: 50%;
    }

    .sideContent {
        margin-right: 10px;
    }

    .sideContent img {
        width: 200px;
        height: 200px;
    }

    .sideContent h1 {
        font-size: 48px;
        margin-bottom: 15px;
    }

    .fields form {
        transform: scale(0.8, 0.8);
    }
}
body {
    font-family: 'Mulish', sans-serif;
}

.pageTitle{
	margin-bottom: 25px;
	display: flex;
}

.pageTitle span{
	line-height: 60px;
	font-weight: 600;
	font-size: 30px;
}

/* tabela de 3 colunas */
table{
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 1px;
    font-size: 15px;
}

 table th, td, tr{
    border-bottom: 3px solid#E6E7E9;
	padding: 10px;
	word-wrap: break-word;
    overflow: hidden;

}


table td{
    width: 30em ;
}


tr:nth-child(1):hover{
	background-color: white;
}

tr:hover{
	background-color: #e8e8e8;
}

table a{
	color: #863231;
	cursor: pointer;
	font-weight: bolder;
}

table a:hover{
	text-decoration: none;
	color: #54000A;
}

tbody tr:nth-child(even){
    background: #F9FAFC;
}

tbody tr:nth-child(even):hover{
    background: #e8e8e8;
}

td:nth-child(2) span{
	color: #537e43;
	cursor: pointer;
	font-weight: bolder;
}

tr:nth-child(1){
		color: #fff;
		background: #54000A;
		box-shadow: inset 0px -1px 0px #E6E7E9;
		pointer-events: none;
		
}

.goBack {
    display: inline-flex;
    width: 100%;
    padding: 5px;
    top: 0;
    height: 60px;
    border-bottom: 1px solid #65656578;
    position: -webkit-sticky;
    position: sticky;
    z-index: 2;
    background-color: white;
	margin-bottom: 10px;
  }
  
.arrowBack{
	margin-top: auto;
	margin-bottom: auto;
	cursor: pointer;
	color: black;
}

.commonWidth{
	width: 80%;
	margin-left: auto;
	margin-right: auto;
}

label{
	font-weight: bold;
}

.loaderDiv{
	margin-left: auto;
	margin-right: auto;
	margin-top: 25%;
	width: -webkit-min-content;
	width: min-content;
	
}

.searchBar input{
    width: 100%;
}

@media only screen and (max-width: 700px) {
	.pageContent{
		width: 100%;
	}

	.commonWidth{
		width: 95%;
    }

    .searchOrder{
        flex-direction: column;
    }

    .searchOrder > *{
        margin-bottom: 10px;
    }

    .listTable{
        overflow-x: scroll;
    }

    table{
        width: 1000px!important;
    }
}

/* Div que engloba a barra de pesquisa e o ordenar por */
.searchOrder{
    display: flex;
    position: relative;
}

/* pesquisar */
.searchBar{
    position: relative;
}
.searchBar input{
    font-size: 24px;
}


/* Ordernar por: */
.orderBy span{
    display: flex;
    position: relative;
    font-family:  var(--font);
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: normal;

    color: #000000;

}


.removeButton{
	content: "";
    background: url(/static/media/delete.d280d280.svg);
    background-repeat: no-repeat;
    background-size: 36px 36px;
    
    display: block;
    height: 32px;
    width: 32px;
    
    outline: none;
    box-shadow: none;
    padding: 0;
}
.removeButton:hover{
	background-size: 37px 37px;
}


/* Navegação de páginas */
.navigation{
    height:100%;
    border-collapse:collapse;
    display : flex;
    position: relative;
}

/* Próxima */
.next{
    position: absolute;
    right: 4em;

}
/* Anterior */
.previous{
    position: absolute;
    left: 4em;

}

.selectBoxContainer{
    position: relative;
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
}

/* Seção do select utilizado nas listagens */
.selectBoxContainer span{
    font-size: 1.5rem;
    color: #6f6f6f;
    position: relative;
    margin: auto;
    margin-right: 20px;
}

.selectBoxContainer  select{
    background: #FFFFFF;
    border: 2px solid #F0F0F0;
    box-sizing: border-box;
    border-radius: 8px;
    height: 50px;
    padding: 10px;
    min-width: 0;
    margin: auto;
}

.selected {
    border: 2px solid #e4e2e2;
    border-radius: 6px;
    
    color: #6f6f6f;
    position: relative;
  
    order: 0;
}

.selected::after {
    content: "";
    background: url(/static/media/down-arrow.80084461.svg);
    background-size: 70%;
    background-repeat: no-repeat;
  
    position: absolute;
    height: 70%;
    width: 32px;
    right: 10px;
    top: 27px;
  
    transition: all 0.4s;
}

.selectBox .optionsContainer.active + .selected::after {
    transform: rotateX(180deg);
    top: -4px;
  }

.selectBox{
    display: flex;
    width: 20rem;
    flex-direction: column;
    margin-top: 0.75rem;
}

.selectBox .optionsContainer {
    background-color: #fff;
    position: absolute;
    border: 2px solid #e4e2e2;
    border-radius: 6px;
    color: #373737;
    width: 16em;
    max-height: 0;
    margin-top: 3.15em;
    opacity: 0;
    transition: all 0.4s;
    overflow: hidden;
    order: 1;
    
    z-index: 3;
}

.selectBox .optionsContainer.active{
    max-height: 240px;
    opacity: 1;
}

.selectBox .option, .selected{
    padding:  12px 24px;
    cursor: pointer;
}

.selectBox .option .radio, .selected.active .radio{
    display: none;
}


.selectBox .option:hover{
    background: #eeecec;
}

.selectBox label{
    cursor: pointer;
}

/* Fim da seção do select utilizado nas listagens */

.searchBar input{
    border: none;
}
* {
    -webkit-print-color-adjust: exact !important;   /* Chrome, Safari, Edge */
    color-adjust: exact !important;          
}

.certification {
    background-color: white;
}

.certification .pageTitle span{
    margin: auto;
}

.logo{
    width: 100px;
    border-radius: 100%;
}

.certificationContent{
    border: 1px solid black;
    position: relative;
    display: table;
    padding: 15px;
    min-width: 1000px;
    max-width: 1000px;
    min-height: 600px;
    max-height: 600px;
    box-sizing: unset;
    font-size: 20px;
    margin-bottom: 20px;
    font-family: Arial;
    overflow: hidden;
    background-color: white;
    
}

.certificationBorder{
    margin: 5px;
    border: 5px solid #54000A;
    padding: 10px;
    min-width: 1000px;
    min-height: 600px;
    background: linear-gradient(0deg, rgb(255 255 255 / 89%), rgb(255 255 255 / 89%)), url(/static/media/certificationBackground.1ad135a6.jpg) no-repeat center;
    background-color: white;

}

.certificationContent .bottom{
    display: flex;
    position:  relative;
    font-weight: bolder;
    height: auto;
}

.bottomLeft{
    display: flex;
    position: absolute;
    bottom: 25px;
    left: 20px;
}

.bottomMiddle{
    position: absolute;
    display: flex;
    bottom: 30px;
    left: 50%;
    transform: translate(-50%, -50%);
}

.bottomMiddleSignature{
    display: flex;
    bottom: 40px;
    justify-content: center;
    margin-top: 30px;
}

.bottomRight{
    display: flex;
    position: absolute;
    bottom: 25px;
    right: 20px;
}

.certificationContent .logos{
    display: flex;
    margin-left: auto;
    margin-right: auto;
}

.certificationContent .logos img{
    margin-left: 20px;
    margin-right: 20px;
    width: 70px;
    height: 70px;
}

.certificationContent .top{
    display: grid;
    max-height: -webkit-min-content;
    max-height: min-content;
    align-self: center;
    text-align: center;
    font-size: 30px;
    padding: 0 50px;
}

.certificationContent .top span:nth-child(1), .certificationContent .top span:nth-child(2){
    font-size: 40px;
    font-weight: bolder;
    color: #54000A;
}

.certificationContent .top span:nth-child(2){
    margin-bottom: 40px;
}

.topics{
    min-height: 400px;
    max-height: 400px;
    overflow: hidden;
    display: flex;
}

.topics .left, .topics .right{
    font-family: monospace;
    font-size: 15px;
    width: 50%;
    text-align: left;
    line-break: auto;
    word-break: break-all;
    line-height: normal;
}

.topics .left{
    border-right: 1px solid black;
}

.certificationContent .middle {
    display: grid;
    position: absolute;
    bottom: 50px;
    width: 50%;
    left: 25%;
    left: 25%;

}

.certificationContent .signatures{
    margin-left: auto;
    margin-right: auto;
    display: flex;
}

.certificationContent .signature {
    display: grid;
    align-self: center;
    text-align: center;
    font-size: 17px;
    margin-left: 20px;
    margin-right: 20px;
}

.up{
    margin-top: -150px;
}



.certificationContent .signatureImg{
    width: 100px;
    border-bottom: 1px solid black;
}

.qrTitle{
    display: grid;
    height: -webkit-min-content;
    height: min-content;

}

.qrTitle span{
    height: -webkit-min-content;
    height: min-content;
    margin: 0 auto;
}
.qrCode{
    margin: 0 auto;
}
.qrCode img{
    width: 100px;
}

.signature label{
    font-size: 12px;
    width: 100px;
    word-wrap: break-word;
    word-break: normal;
}

.certificationText{
    font-size: 26px;
}

#divToPrint{
    margin: auto;
    width: -webkit-min-content;
    width: min-content;
}

#printButton{
    display: grid;
    margin: auto;
}

@media print{@page {size: landscape}}

@media print {
    .certificationContent { page-break-after: always; }
}
